<template>
  <div class="container">
    <!-- <header>
       <img
        src="@/assets/newImg/arrow_left.png"
        width="18px"
        height="15px"
        @click="$router.go(-1)"
      />
    </header> -->
    <p class="login_Title">{{ $t('login').lang }}</p>
    <div class="lang_list">
      <div class="lang_item" v-for="(item, index) in lang_list" :key="index" @click="selectLang(index)">
        <p class="txt">{{ item.text }}</p>
        <van-icon v-if="item.selected"  name="success" size="22" color="#4a30b7" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      checked: false,
      lang_list: [
        { text: 'English', lang: 'en', selected: false },
        { text: '中文', lang: 'zh', selected: false },
        // { text: 'ภาษาไทย', lang: 'ty', selected: false },//泰语
        // { text: '日本語です', lang: 'jp', selected: false },//日语
        // { text: '한국인', lang: 'hy', selected: false },//韩语
        // { text: 'Indonesia', lang: 'yn', selected: false },//印尼语
        // { text: 'Việt nam', lang: 'yuenan', selected: false },//越南文
        // { text: 'កម្ពុជា', lang: 'gmw', selected: false },//高棉文
        // { text: 'Français', lang: 'fy', selected: false },//法语
        // { text: 'Español', lang: 'xby', selected: false },//西班牙语
        // { text: 'Русский язык', lang: 'ey', selected: false },//俄语
        // { text: 'Português', lang: 'pty', selected: false },//葡萄牙语
        // { text: 'Deutsch', lang: 'dy', selected: false },//德语
      ]
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.lang_list.forEach((item, index, arr) => {
      if (item.lang == localStorage.getItem('language')) item.selected = true
    })
  },
  methods: {
    selectLang(index) {
      var lang = this.lang_list[index].lang
      this.lang_list.forEach((item, index, arr) => {
        item.selected = false
      })
      this.lang_list[index].selected = true
      this.$i18n.locale = lang
      localStorage.setItem('language', lang)
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 0 15px;
  background: #ffffff;

  .pwd-icon {
    width: 15px;
    height: 15px;
  }

  header {
    color: #eeeeee;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
  }

  .login_Title {
    margin: 30px 0px 21px;
    font-size: 29px;
    font-weight: 600;
    color: #515151;
  }

  .lang_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid #f5f5f5;
    .txt {
      font-size: 16px;
      color: #515151;
    }
  }
}
</style>
